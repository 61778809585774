import React, {useState} from 'react';
import { Grid, Button, Typography, Box, Tooltip, Paper, useTheme, useMediaQuery } from '@mui/material';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import 'dayjs/locale/hu'; // Import Hungarian locale
import { styled } from '@mui/material/styles';

dayjs.extend(isBetween);
dayjs.locale('hu'); // Set locale to Hungarian

const CalendarDay = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
        zIndex: 1,
    },
}));

const MonthCalendar = ({ currentMonth, selectedRange, setSelectedRange, hoverDate, setHoverDate, disabledRanges, minDate, maxDate, disabledColor, reserveColor }) => {
    const startOfMonth = currentMonth.startOf('month');
    const endOfMonth = currentMonth.endOf('month');
    const startOfWeek = startOfMonth.startOf('week').add(0, 'day');
    const endOfWeek = endOfMonth.endOf('week').add(1, 'day');

    const days = [];
    let day = startOfWeek;
    while (day.isBefore(endOfWeek)) {
        days.push(day);
        day = day.add(1, 'day');
    }

    const isDisabled = (date) => {
        if (minDate && date < minDate) return true
        if (maxDate && date > maxDate) return true
        return disabledRanges?.some((range) => date.isBetween(range.start_date, range.end_date, 'day', '()'));
    }

    const handleDateClick = (date) => {
        if (isDisabled(date)) return;

        if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
            setSelectedRange({ start: date, end: null });
        } else if (selectedRange.start && !selectedRange.end) {
            if (date.isAfter(selectedRange.start)) {
                // Check if the range contains any disabled dates
                const rangeHasDisabledDates = days.some(
                    (d) => isDisabled(d) && d.isBetween(selectedRange.start, date, 'day', '[]')
                );
                if (!rangeHasDisabledDates) {
                    setSelectedRange({ ...selectedRange, end: date });
                }
            } else {
                // If the date is before the start, swap start and end
                const rangeHasDisabledDates = days.some(
                    (d) => isDisabled(d) && d.isBetween(date, selectedRange.start, 'day', '[]')
                );
                if (!rangeHasDisabledDates) {
                    setSelectedRange({ start: date, end: selectedRange.start });
                }
            }
        }
    };

    const isSelected = (date) => {
        const { start, end } = selectedRange;
        return start && end && date.isBetween(start, end, 'day', '[]');
    };

    const isUnderHovered = (date) => {
        const { start, end } = selectedRange;
        return start && !end && hoverDate && date.isBetween(start, hoverDate, 'day', '[]');
    };

    const getGradientStyles = (disabledColor, reserveColor) => ({
        disabled: {
            start: `linear-gradient(to top left, ${disabledColor} 50%, white 50%)`,
            end: `linear-gradient(to bottom right, ${disabledColor} 50%, white 50%)`,
            endSelectionStart: `linear-gradient(to bottom right, ${disabledColor} 50%, ${reserveColor} 50%)`,
            startSelectionEnd: `linear-gradient(to top left, ${disabledColor} 50%, ${reserveColor} 50%)`,
        },
        selection: {
            start: `linear-gradient(to top left, ${reserveColor} 50%, white 50%)`,
            end: `linear-gradient(to bottom right, ${reserveColor} 50%, white 50%)`,
        }
    });

    const getBackgroundStyle = (date) => {
        if (isDisabled(date)) return disabledColor;

        const gradients = getGradientStyles(disabledColor, reserveColor);
        const isPreviousDisabled = isDisabled(date.subtract(1, 'day'));
        const isNextDisabled = isDisabled(date.add(1, 'day'));
        const isSelectionStart = date.isSame(selectedRange.start, 'day');
        const isSelectionEnd = date.isSame(selectedRange.end, 'day');
        const isCurrentlyHovered = date.isSame(hoverDate, 'day');

        // Handle disabled date transitions
        if (isPreviousDisabled && isNextDisabled) return disabledColor;
        if (isPreviousDisabled) {
            if (isSelectionStart || isCurrentlyHovered) return gradients.disabled.endSelectionStart;
            return gradients.disabled.end;
        }
        if (isNextDisabled) {
            if (isSelectionStart || isSelectionEnd || isCurrentlyHovered) return gradients.disabled.startSelectionEnd;
            return gradients.disabled.start;
        }

        // Handle selection transitions
        if (isSelectionStart) {
            return !selectedRange.end && hoverDate < date 
                ? gradients.selection.end 
                : gradients.selection.start;
        }

        if (selectedRange.start && !selectedRange.end && isCurrentlyHovered) {
            if (hoverDate >= selectedRange.start) return gradients.selection.end;
            if (isPreviousDisabled) return gradients.disabled.endSelectionStart;
            return gradients.selection.start;
        }

        if (isSelectionEnd) return gradients.selection.end;
        if (isSelected(date)) return reserveColor;
        if (isUnderHovered(date)) return reserveColor;
        return 'white';
    };

    const getDateTooltip = (date) => {
        if (isDisabled(date)) return 'Nem elérhető dátum';
        if (date.isSame(selectedRange.start, 'day')) return 'Érkezés';
        if (date.isSame(selectedRange.end, 'day')) return 'Távozás';
        return date.format('YYYY. MMMM D.');
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper elevation={3} sx={{ p: {xs: 1, md: 3}, borderRadius: 2, height: '100%' }}>
                {/* Header */}
                <Grid container justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                        {currentMonth.format('MMMM YYYY')}
                    </Typography>
                </Grid>

                {/* Days of Week */}
                <Grid container sx={{ mb: 1 }}>
                    {['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V'].map((day) => (
                        <Grid item xs={1.7} key={day} textAlign="center">
                            <Typography 
                                variant="subtitle1" 
                                sx={{ 
                                    fontWeight: 'bold',
                                    color: 'text.secondary'
                                }}
                            >
                                {day}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                {/* Dates */}
                <Grid container spacing={1}>
                    {days.map((date) => (
                        <Grid item xs={1.7} key={date.toString()}>
                            {date.month() === currentMonth.month() ? (
                                <Tooltip title={getDateTooltip(date)} arrow>
                                    <CalendarDay
                                        elevation={isSelected(date) || isUnderHovered(date) ? 3 : 1}
                                        onMouseEnter={() => setHoverDate(date)}
                                        onClick={() => handleDateClick(date)}
                                        sx={{
                                            background: getBackgroundStyle(date),
                                            color: isDisabled(date) ? 'text.disabled' : 'text.primary',
                                            cursor: isDisabled(date) ? 'not-allowed' : 'pointer',
                                            border: date.isSame(dayjs(), 'day') ? '2px solid #1976d2' : 'none',
                                        }}
                                    >
                                        <Typography 
                                            variant="body1"
                                            sx={{
                                                fontWeight: date.isSame(dayjs(), 'day') ? 'bold' : 'normal',
                                            }}
                                        >
                                            {date.date()}
                                        </Typography>
                                    </CalendarDay>
                                </Tooltip>
                            ) : (
                                <CalendarDay sx={{ visibility: 'hidden' }} />
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Box>
    );
};

const Calendar = ({disabledRanges, reference, minDate=null, maxDate=null, disabledColor='#f5f5f5', reserveColor='#e65a61'}) => {
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [selectedRange, setSelectedRange] = useState({start: null, end: null});
    const [hoverDate, setHoverDate] = useState(null);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const handleMonthChange = (increment) => {
        setCurrentMonth(currentMonth.add(increment, 'month'));
    };

    return (
        <Box sx={{ maxWidth: '100%', mx: 'auto' }}>
            {/* Navigation */}
            <Grid container justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
                <Button 
                    variant='contained' 
                    onClick={() => handleMonthChange(-1)}
                    sx={{ minWidth: 120, mr: 2 }}
                >
                    Előző hónap
                </Button>
                <Button 
                    variant='contained' 
                    onClick={() => handleMonthChange(1)}
                    sx={{ minWidth: 120, ml: 2 }}
                >
                    Következő hónap
                </Button>
            </Grid>

            {/* Calendar Grid */}
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <MonthCalendar
                        currentMonth={currentMonth}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        hoverDate={hoverDate}
                        setHoverDate={setHoverDate}
                        disabledRanges={disabledRanges}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabledColor={disabledColor}
                        reserveColor={reserveColor}
                    />
                </Grid>
                {isLargeScreen && (
                    <Grid item xs={12} lg={6}>
                        <MonthCalendar
                            currentMonth={currentMonth.add(1, 'month')}
                            selectedRange={selectedRange}
                            setSelectedRange={setSelectedRange}
                            hoverDate={hoverDate}
                            setHoverDate={setHoverDate}
                            disabledRanges={disabledRanges}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabledColor={disabledColor}
                            reserveColor={reserveColor}
                        />
                    </Grid>
                )}
            </Grid>

            <form ref={reference}>
                <input type="hidden" name='start' value={selectedRange?.start?.format('YYYY-MM-DD')}/>
                <input type="hidden" name='end' value={selectedRange?.end?.format('YYYY-MM-DD')}/>
            </form>
        </Box>
    );
};

export default Calendar;